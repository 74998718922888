import React, { FC } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './../hooks/contexts/AuthContext';

interface Props {
  children: React.ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const location = useLocation();

  return isLoggedIn ? 
    ( 
      <>
        {children}
      </>
    ) : ( 
      <Navigate to='/login' replace state={{ path: location.pathname}} />  
    );
}