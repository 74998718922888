import React from 'react';
import { Box, Heading, Text, Link } from 'rebass';

export interface SearchResultType {
  id: string;
  title: string;
  link: string;
  text: string;
  score: number;
}

interface SearchResultsProps {
  searchResults: SearchResultType[];
}

export const SearchResultsComponent: React.FC<SearchResultsProps> = ({ searchResults }) => {
  return (
    <Box>
      {searchResults.map((result, index) => (
        <Box key={index} marginBottom="16px">
          <Heading variant="h3">
            <Link href={result.link}>{result.title}</Link>
          </Heading>
          <Text>{result.text}</Text>
        </Box>
      ))}
    </Box>
  );
};

