import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 1.5; // Typical line height
  height: calc(1.5em * 20 + 20px); // 10 rows height (line height * rows) + padding  
`;

