import { useQuery } from 'react-query';
import { projectName } from '../../i18n';
import api from '../../common/api';

export type GetVectorByIdType = {
  text: string,
  url: string,
  [key: string]: any
}  

const fetchVectorById = async (
  { id }: { id: string}) : Promise<{ results: GetVectorByIdType}> => {
    const response: { data: { results: GetVectorByIdType} } = await api.get(`${projectName}/vectors/get/${id}`);

  return response.data;
}

const useGetVectorById = (id: string) => {
  return useQuery(['ProjectConversations', id], () => fetchVectorById({ id }));
}

export default useGetVectorById;