import React, { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { backendUrl } from '../i18n';
import { ErrorMessage } from './ErrorMessage';


export const PasswordForgotForm: React.FC = () => {
  const frontEndUrl = window.location.origin;
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const forgotPasswordMutation = useMutation((email: string) =>
    axios.post(`${backendUrl}/forgot_password`, { email, frontEndUrl })
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage('');

    try {
      if(email.length < 8) {
        setErrorMessage('Email must be at least 8 characters long');
        return;
      };
      
      await forgotPasswordMutation.mutateAsync(email);
      // Handle password update success
    } catch (error) {
      // Handle password update error
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      {!forgotPasswordMutation.isSuccess &&
        <>
          <label>
            Type your e-mail: <br />
            <input
              minLength={8}
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label><br /><br />
        </>
      }  
      {!forgotPasswordMutation.isSuccess && 
        <button type="submit" disabled={forgotPasswordMutation.isLoading}>
          {forgotPasswordMutation.isLoading ? 'Submitting...' : 'Submit'}
        </button>
      }

    {forgotPasswordMutation.isSuccess &&
      <p>Your request has been received, please check your e-mail with instructions on how to change your password.</p>
    }
    </form>
  );
};

