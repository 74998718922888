import { useEffect, useRef } from 'react';

type ValueWatcher = {
  currentValue: any;
  onChange: (newValue: any) => void;
};

export const useWatchValue = (value: any, callback: (newValue: any) => void) => {
  const watcherRef = useRef<ValueWatcher | null>(null);

  useEffect(() => {
    if (!watcherRef.current) {
      watcherRef.current = { currentValue: value, onChange: callback };
    } else {
      const watcher = watcherRef.current;

      if (watcher.currentValue !== value) {
        watcher.currentValue = value;
        watcher.onChange(value);
      }
    }
  }, [callback, value]);

  useEffect(() => {
    watcherRef.current!.onChange = callback;
  }, [callback]);

  // Cleanup the watcher on unmount
  useEffect(() => {
    return () => {
      watcherRef.current = null;
    };
  }, []);

  // You can also return the current value if needed
  return value;
};

