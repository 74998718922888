import React from 'react';
import { Flex, Link } from 'rebass';

interface PaginationProps {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageNumber,
  setPageNumber,
  totalPages,
}) => {
  const handlePageClick = (page: number) => {
    if (page !== pageNumber) {
      setPageNumber(page);
    }
  };

  return (
    <Flex width={'300px'} flexWrap={'wrap'}>
      {Array.from({ length: totalPages }, (_, index) => {
        const page = index + 1;
        const isCurrentPage = page === pageNumber;

        return (
          <Link
            key={page}
            variant={isCurrentPage ? 'underline' : 'default'}
            sx={{ cursor: isCurrentPage ? 'default' : 'pointer' }}
            onClick={() => handlePageClick(page)}
            style={{ marginRight: '5px'}}
          >
            {page}
          </Link>
        );
      })}
    </Flex>
  );
};

export default Pagination;
