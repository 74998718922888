import { useMutation } from 'react-query';
import { backendUrl, projectName } from '../i18n';
import axios from 'axios';

type AskVariables = {
  question: string;
  category: string;
  pyetShqipJwtToken: string;
  userId: string;
  conversationId: string;
};

const useAskMutation = () => {
  const askMutation = useMutation((variables: AskVariables) => {
    return axios.post(`${backendUrl}/askCustomAiAssistant`, {
      projectName,
      question: variables.question,
      category: variables.category,
      pyetShqipJwtToken: variables.pyetShqipJwtToken,
      userId: variables.userId,
      conversationId: variables.conversationId,
    });
  });

  return askMutation;
};

export default useAskMutation;