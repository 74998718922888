import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from './ErrorMessage';
import { Flex } from 'rebass';
import theme from '../theme';


// Styled components
const SearchContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SearchInput = styled.input`
  padding: 8px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

const SearchButton = styled.button`
  padding: 8px;
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  background-color: ${theme.colors.primary}};
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color:  ${theme.colors.third};
  }
`;

// Search component
interface SearchProps {
  query?: string;
  isLoading: boolean;
  isError?: boolean;
  onSearch: (value: string) => void;
}



export const SearchField: React.FC<SearchProps> = ({ 
  query,
  isLoading,
  isError, 
  onSearch, 
}) => {
  const [searchValue, setSearchValue] = React.useState(query ||  '');

  const handleSearch = () => {
    onSearch(searchValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const enterSubmit = (e: any) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      return handleSearch();
    }
  }  

  return (
    <SearchContainer>
      <Flex flexDirection={'column'}>
        <Flex>
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={handleChange}
            onKeyPress={enterSubmit}
          />
          <SearchButton onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Searching...' : 'Search'}
          </SearchButton>
        </Flex>
        
        <br />
        {isError && 
          <>
            <br />
            <ErrorMessage message="There was an error performing the search" />
            <br />          
          </>
        }

      
      </Flex>
    </SearchContainer>
  );
};


