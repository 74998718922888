import { useQuery } from 'react-query';
import api from '../../common/api';

export interface ConversationAiResponseType {
  conversationId: string,
  chainResponse: string,
  sources: { [key: string]: any }[],
  chatHistory: string[],
  question: string,
  createdAt: Date,
  updatedAt: Date
}

const fetchConversationAiResponses = async (
  { conversationId }: { conversationId: string}) : Promise<ConversationAiResponseType[]> => {
  
    const response: { data: { aiResponses: ConversationAiResponseType[] } } = await api.post(`/conversations/showAiResponses`, { 
      conversationId
    });

  return response.data.aiResponses;
}

const useGetConversationAiResponses = (conversationId: string) => {
  return useQuery(['ConversationAiResponses', conversationId], () => fetchConversationAiResponses({ conversationId}));
}

export default useGetConversationAiResponses;