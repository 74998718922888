import React from 'react';
import {
  Logo
} from './styled/various';
import { useIsMobile } from '../hooks/useIsMobile';
import { BackButton } from "./styled/various";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './../hooks/contexts/AuthContext';
import { Flex, Link } from 'rebass';
import { logoFileName } from '../i18n';


export const Header = () => {
  const { isLoggedIn, logout } = React.useContext(AuthContext);
  const isMobile = useIsMobile();
  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.removeItem('authToken');
    logout();
    navigate('/');
  };

  const goToLoginPage = () => {
    navigate('/login');
  };

  const goToAddVectorRecordPage = () => {
    navigate('/admin/vector/add');
  };  

  return ( 
    <Flex>
        <Link href='/' sx={{ width: '100%' }}>
          <Logo src={`/logos/${logoFileName}`} alt="Logo" style={{ marginBottom: '15px'}} width={isMobile ? '100%' : '70%'} />
        </Link>
        {isLoggedIn ? (
          <>
          <BackButton onClick={goToAddVectorRecordPage} style={{ width:'300px', height:'50px', marginLeft: '30px'}}>
            Add Vector Record
          </BackButton>                    
          <BackButton onClick={handleLogout} style={{ width:'100px', height:'50px', marginLeft: '30px'}}>
            Logout
          </BackButton>
          </>  
        ) : (
          <BackButton onClick={goToLoginPage} style={{ width:'100px', height:'50px', marginLeft: '30px'}}>
            Login
          </BackButton>         )}

    </Flex>
  ); 
}; 