import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

export const DeleteButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  background-color: ${theme.colors.fourth};
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;


  ${(props) => props.disabled && `
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
  `}

  ${(props) => !props.disabled && `
    color: white;
    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      background-color: #c0392b;
    }    
  `}
  
  `;

export function DeleteButtonComponent(props: any) {
  return (
    <DeleteButton onClick={props.onClick}>
      {props.children || 'Delete'}
    </DeleteButton>
  );
}


