import {
  Flex,
} from 'rebass'
import { 
  TextArea, 
  SubmitButton, 
  ErrorMessage,
  SentenceComponent,
} from './styled/various';
import { useTranslation } from 'react-i18next';
import { Comment } from 'react-loader-spinner';
import { ConversationType } from '../components/styled/various'; 
import theme from '../theme'; 
import { VectorMetadataType } from './styled/various';

type ConversationComponentProps = {
  isMobile: boolean,
  isLoadingMutation: boolean,
  currentConversation: ConversationType | undefined,
  errorMessage: string, 
  textAreaValue: string,
  captchaToken: string,
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
  handleSubmit: (event: React.MouseEvent) => void,
  userId?: string,
  conversationId?: string,
  conversationIsLoading?: boolean,
  sources?: VectorMetadataType[],
} 

export const Conversation = ({
  isMobile,
  isLoadingMutation,
  currentConversation,
  conversationIsLoading,
  errorMessage,
  textAreaValue,
  captchaToken,
  handleChange,
  handleSubmit,
  sources
}: ConversationComponentProps) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection='column'>
      {conversationIsLoading && 
        <Comment
          visible={true}
          height="80"
          width="80"
          ariaLabel="comment-loading"
          wrapperStyle={{}}
          wrapperClass="comment-wrapper"
          color="#fff"
          backgroundColor="#000" 
        />
      }

      {!currentConversation && !isLoadingMutation &&
        <h2 
          style={{ 
            marginTop: isMobile ? '20px' : '' 
          }}
        >
          {t('startANewConversation')}
        </h2>
      }
      
      {currentConversation && currentConversation.sentences.map((sentence: string, index: number) => {
        // Assistant
        let backgroundColor = theme.colors.secondary;
        let color = 'black';
        let author = t('authors.assistantName');

        if (index % 2 === 0) {
          // You
          backgroundColor = 'white';
          color = 'black';
          author = t('authors.yourQuestionName');
        }

        return (
          <>
            <SentenceComponent
              key={index}
              sentenceText={sentence}
              author={author}
              color={color}
              backgroundColor={backgroundColor}
              customWidth={isMobile ? '270px' : '100%'} 
            />          

          </>
          );
      })}

          <form>

          {isLoadingMutation ? (
            <Flex
              justifyContent='center'
              alignItems='center'
              mb='20px'
            >
              <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor="#000" />
              <p>{t('generatingResponse')}</p>
            </Flex>
          ) : (
            <>
              {errorMessage &&
                <ErrorMessage>
                  {errorMessage}
                </ErrorMessage>}

              {/* {sources && sources.slice(0, 1).map((source, index) => {
                return (  
                  <>
                    <span>Burimi kryesor i përgjigjes se fundit: </span>
                    <Link href={source.url} target="_blank" rel="noopener noreferrer">
                      {source.url}
                    </Link>                  
                  </>
                )}
              )} */}
                <>      
                  <TextArea
                    style={{
                      marginTop: '10px'
                    }}
                    rows={5}
                    cols={5}
                    placeholder={t('writeQuestionHere') || ''}
                    value={textAreaValue}
                    onChange={handleChange} 
                  />

                  <SubmitButton
                    onClick={(e) => handleSubmit(e)}
                    disabled={!captchaToken || !localStorage.getItem('userId') || textAreaValue.length < 5 || Boolean(errorMessage)}
                  >
                    {t('sendQuestion')}
                  </SubmitButton>
                </>                
            </>
          )}
          </form> 
    </Flex>  
  )};