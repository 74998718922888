import React from 'react';
import {
  useParams,
  useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Heading
} from 'rebass';
import { 
  BackButton,
  TextArea,
  SubmitButton,
  Label 
} from "./styled/various";
import { DeleteButtonComponent } from "./styled/DeleteButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {  } from './styled/various';
import { DeleteDialog } from './DeleteDialog';
import { useMutation } from 'react-query';
import useGetVectorById from '../hooks/admin/useGetVectorById';
import { GetVectorByIdType } from '../hooks/admin/useGetVectorById';
import { backendUrl, projectName } from '../i18n';
import api from '../common/api';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

type UpdateVectorByIdType = {
  id: string,
  text: string,
  metadata: GetVectorByIdType | {}
}  

export const AdminVectorModifyRecord = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { vectorId } = useParams();

  const [textAreaValue, setTextAreaValue] = React.useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);

  const { data: vectorData, status: retrievalStatus } = useGetVectorById(vectorId || ''); 
  
  const updateMutation = useMutation<AxiosResponse<any, any>, Error, UpdateVectorByIdType>(async () => {
    const response = await api.post(`${backendUrl}/${projectName}/vectors/upsertOne`, {
      id: vectorId || '',
      text: textAreaValue,
      metadata: vectorData?.results || {}
    });
    return response;
  }, {
      onSuccess: () => {
        toast.info('Record updated successfully');
      }
    }
  );

  const { mutate: mutationUpdate, isLoading: isLoadingUpdate } = updateMutation; 

  const deleteMutation = useMutation<AxiosResponse<any, any>, Error, { vectorId: string }>(async () => {
    const response = await api.delete(`${backendUrl}/${projectName}/vectors/delete/${vectorId}`);
    return response;
  }, {
    onSuccess: () => {
      toast.info('Record deleted');
      navigate('/admin');
    }
  });

  const { mutate: mutationDelete, isLoading: isLoadingDelete } = deleteMutation;


  const handleChange = (event: any) => {
    setTextAreaValue(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    mutationUpdate({
      id: vectorId || '', 
      text: textAreaValue, 
      metadata: vectorData?.results || { url: '' }
    } );

  };

  const handleDeleteConfirm = async() => {
    // Perform the delete operation here
    mutationDelete({ vectorId: vectorId || '' });        
    setShowDeleteDialog(false);    
  };

  const handleDeleteCancel = () => {
    setShowDeleteDialog(false);
  };

  React.useEffect(() => {
    if (retrievalStatus === 'success') {
      setTextAreaValue(vectorData.results.text);
    }
  }, [retrievalStatus, vectorData, vectorId]);

  const goBack = () => {
		navigate(-1);
	}

  return (
    <Flex flexDirection={'column'}>
        <BackButton onClick={goBack} disabled={isLoadingUpdate || isLoadingDelete} style={{ marginBottom: '50px'}}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px'}} />
          Kthehu mbrapa
        </BackButton>  

        {showDeleteDialog && 
          <DeleteDialog
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        }

      {vectorData?.results?.url &&
        <Heading>Title/URL: {vectorData?.results?.url}</Heading>
      }


      <Label>Text in the database: <small>(*required)</small></Label> 
      <TextArea
        style={{
          marginTop: '10px'
        }}
        height="400px"
        placeholder={''}
        value={textAreaValue}
        onChange={handleChange} 
      />

      <br />
      <Flex 
        width={'70%'}
      >
        <SubmitButton
            disabled={isLoadingUpdate || isLoadingDelete || textAreaValue.length < 10}
            onClick={(e) => handleSubmit(e)}
        >
            {t('admin.modifyRecord')}
        </SubmitButton>

        <DeleteButtonComponent 
          disabled={isLoadingUpdate || isLoadingDelete}
          onClick={() => setShowDeleteDialog(true)}
        >
            {t('admin.deleteRecord')}
        </DeleteButtonComponent>
      </Flex>  

    </Flex>
  );
}; 