import React from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query'
import './i18n';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { Main } from './components/Main';
import { AuthProvider } from './hooks/contexts/AuthContext';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  const queryClient = new QueryClient();
  const [captchaToken, setCaptchaToken] = React.useState<string>('');

  const verifyRecaptchaCallback = React.useCallback((token: string) => {
    setCaptchaToken(token)
  }, []); 

  React.useEffect(() => {
    const refreshApp = () => {
      window.location.reload();
    };

    const refreshInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const refreshTimeout = setTimeout(refreshApp, refreshInterval);

    return () => {
      clearTimeout(refreshTimeout);
    };
  }, []);

  return (
    <div className="App" id="outer-container">
      <div id="page-wrap">

        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider reCaptchaKey="6LfodNQkAAAAAPI4geTx4WoVli3d_V5abAD2VnOz">
            <GoogleReCaptcha 
              onVerify={verifyRecaptchaCallback}
            />
              <AuthProvider>
                <ThemeProvider theme={theme}>
                  <ToastContainer position="top-center"  />
                  <Main captchaToken={captchaToken} />
                </ThemeProvider>  
              </AuthProvider>
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
 
      </div>
    </div>
  );
}

export default App;
