import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { backendUrl } from '../i18n';
import { AuthContext } from './../hooks/contexts/AuthContext';
import { Link } from 'react-router-dom';
import theme from '../theme';

// Styled Components
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;

// Login Component
export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = React.useContext(AuthContext);

  const loginMutation = useMutation(async () => {
    const response = await axios.post(`${backendUrl}/login`, {
      email: username,
      password,
    });
    return response.data.authToken;
  });

  const handleLogin = () => {
    loginMutation.mutate();
  };

  if (loginMutation.isSuccess) {
    const authToken = loginMutation.data;    
    login(authToken);
    navigate('/admin');
  }

  const enterSubmit = (e: any) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      return handleLogin();
    }
  }  

  return (
    <LoginContainer>
      {loginMutation.isError && <p style={{ color: 'red'}}>Invalid credentials for login, please try again. If the problem persists, contact the administrator. </p>}

      <Input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={enterSubmit}
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={enterSubmit}
      />
      <Button onClick={handleLogin} disabled={loginMutation.isLoading} style={{ backgroundColor: theme.colors.primary }}>
        {loginMutation.isLoading ? 'Logging in...' : 'Login'}
      </Button>
      <br />
      <Link
            key={'forgotPassword'}
            to={'/forgotPassword'}
            style={{ 
              marginRight: '5px',
              color: theme.colors.primary,
            }}
          >
            Forgot Password?
      </Link>
    </LoginContainer>
  );
};


