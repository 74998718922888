import React from 'react';
import styled from 'styled-components';

interface ErrorMessageProps {
  message: string;
}

const ErrorContainer = styled.div`
  padding: 10px;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 4px;
`;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return <ErrorContainer>{message}</ErrorContainer>;
};

