import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { backendUrl } from '../i18n';

interface ResetPasswordParams extends Record<string, string | undefined> {
  tempString: string;
}

export const PasswordChangeForm: React.FC = () => {
  const { userId, tempString } = useParams<ResetPasswordParams>();
  const [newPassword, setNewPassword] = useState('');

  const resetPasswordMutation = useMutation((newPassword: string) =>
    axios.post(`${backendUrl}/create_new_password`, { userId, newPassword, tempString })
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await resetPasswordMutation.mutateAsync(newPassword);
      // Handle password update success
    } catch (error) {
      // Handle password update error
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!resetPasswordMutation.isSuccess &&
        <>
          <label>
            New Password(minimum 8 letters): <br />
            <input
              minLength={8}
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </label><br /><br />
        </>
      }  
      {!resetPasswordMutation.isSuccess && 
        <button type="submit" disabled={resetPasswordMutation.isLoading}>
          {resetPasswordMutation.isLoading ? 'Updating...' : 'Submit'}
        </button>
      }

    {resetPasswordMutation.isSuccess &&
      <p>Password has been changed successfully</p>
    }
    </form>
  );
};

