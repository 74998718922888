import axios from 'axios';
import { backendUrl } from '../i18n';

// Create an instance of Axios
const api = axios.create({
  baseURL: backendUrl
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken');
    
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
