import React, { createContext, useState } from 'react';
import jwt from "jwt-decode";

interface Props {
  children: React.ReactNode;
}

interface JwtTokenType {
  uniqueId: string;
  type: string;
  iat: number;
  exp: number;
}

// Create a new context
interface AuthContextProps {
  isLoggedIn: boolean;
  login: (authToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

// AuthProvider Component
const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(Boolean(localStorage.getItem('authToken')));

  const login = (authToken: string) => {
    setIsLoggedIn(true);
    localStorage.setItem('authToken', authToken);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('authToken');
  };

  React.useEffect(() => {
    const token = localStorage.getItem('authToken') || '';    
    const checkTokenExpiration = () => {
      try {
        const decoded = jwt<JwtTokenType>(token);
        const currentTimestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds

        if (decoded && decoded.exp > currentTimestamp) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Failed to decode token:');
      }
    };

    checkTokenExpiration();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
