import React from 'react';
import styled from 'styled-components';
import { Button, Flex, Text } from 'rebass';
import theme from '../theme';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dialog = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const RedButton = styled(Button)`
  background-color: ${theme.colors.primary};
`;

const RedDarkButton = styled(Button)`
  background-color: ${theme.colors.fourth};
`;

export const DeleteDialog: React.FC<Props> = ({ onConfirm, onCancel }) => {
  return (
    <Overlay>
      <Dialog>
        <Text mb={4}>Are you sure you want to delete this item?</Text>
        <Flex justifyContent="space-between">
          
          <RedButton onClick={onCancel}>
            Cancel
          </RedButton>
          
          <RedDarkButton onClick={onConfirm} variant="primary">
            Delete
          </RedDarkButton>
        
        </Flex>
      </Dialog>
    </Overlay>
  );
};


