
type DefaultTheme = {
  colors: {
    primary: string;
    secondary: string;
    third: string;
    fourth: string;
    // Add more colors as needed
  };
};

const theme: DefaultTheme = {
  colors: {
    primary: '#dc252d',
    secondary: '#f6ced0',
    third: '#e77377',
    fourth: '#a41c24',
    // Add more colors as needed
  },
};

export default theme;