import * as React from "react";
import {
  Flex,
} from "rebass";
import { useParams, useNavigate } from 'react-router-dom';
import { SearchField } from './Search';
import { SearchResultsComponent } from './SearchResultsComponent';
import { BackButton } from './styled/various';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import api from "../common/api";
import { backendUrl, projectName } from "../i18n";
import { useMutation } from "react-query";


export const SearchPage = () => {
  let { query } = useParams();
  const navigate = useNavigate();

  // data type is SearchResultType[]
  const { data, mutate: mutateSearch, isLoading } = useMutation(async ({ question } : { question: string}) =>
    await api.post(`${backendUrl}/${projectName}/vectors/search/text`, { question }));  

  const handleSearch = async (query: string) => {
    navigate(`/admin/search/${query}`);
  };

  const goBack = () => {
		navigate(-1);
	}

  // Search occurs only on page load. Whenever the query changes, we run navigate(`/admin/search/${query}`) in handleSearch();
  React.useEffect(() => {
    mutateSearch({
      question: query || ''
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <h1>Admin page</h1>
        <SearchField 
          query={query || ''}
          isLoading={isLoading}
          onSearch={handleSearch}
        />
      </Flex>

      {isLoading ? (
        <div>Loading...</div>
        ) : (
          <Flex>
                <BackButton onClick={goBack} style={{ marginBottom: '50px', height: '40px', marginRight: '10px'}}>
                  <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px'}} />
                  Kthehu mbrapa
                </BackButton>  


              <Flex width={3 / 4} flexDirection={'column'}>

                  {data?.data.results.length > 0 && 
                    <SearchResultsComponent searchResults={data?.data.results}  />
                  }

              </Flex> 


        </Flex>      
      )}
    </>
  );
};