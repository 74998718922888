import React from 'react';
import { useTranslation } from 'react-i18next';
import useGetDocumentConversations from '../hooks/useGetDocumentConversations';
import { UploadFile } from './UploadFile';
import { conversationIdAndTopicNameType } from './styled/various';
import { 
  Flex,
} from 'rebass';
import useConversationSentences from '../hooks/useGetConversationSentences';
import { Conversation } from './Conversation';
import { MainPromptProps, colors } from './styled/various';
import { isEmpty } from 'lodash';
import { ConversationType } from '../components/styled/various';
import { MainButton } from './MainButton';
import { DropdownCustom } from './DropDownCustom';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Comment } from 'react-loader-spinner';

type DocumentPromptType = MainPromptProps & { 
  setConversationId: (conversationId: string) => void,
  currentConversationId: string | null,
 }

export const DocumentPrompt = ({ 
  userId,
  isMobile,
  isLoadingMutation,
  errorMessage,
  textAreaValue,
  captchaToken,
  handleChange,
  handleSubmit,
  documentMode,
  setDocumentMode,
  setConversationId,
  currentConversationId 
}: DocumentPromptType) => {

  const { t } = useTranslation();

  const [currentFilename, setCurrentFilename] = React.useState<ConversationType["filename"] | null>(null);
  const [isFileUploaded, setIsFileUploaded] = React.useState<boolean>(false);

  const { data: currentConversation, refetch: refetchCurrentConversation, isLoading: currentConversationIsLoading } = useConversationSentences(userId || '', currentConversationId || '', 
      // onSuccess
      (documentConversation: ConversationType) => {
        if(documentMode !== 'addDocument' || isFileUploaded) {
          setCurrentFilename(documentConversation.filename);
        }
      }
  );

  const { data: documentConversations, refetch: refetchDocumentConversations } = useGetDocumentConversations(userId || '',
    // onSuccess
    (documentConversations: conversationIdAndTopicNameType[] ) => {
      
      if(documentMode !== 'addDocument') {
        if(!isEmpty(documentConversations)) {
          setConversationId(documentConversations[0].conversationId);
          refetchCurrentConversation();
        }
      }
    }  
  );

      
  const documentsForDropdown = !isEmpty(documentConversations) && documentConversations.map((row: conversationIdAndTopicNameType) => {
    return {
      label: row.conversationId,
      description: row.topicName
    }});


  return (
    <Flex 
      flexDirection='column' 
      width={3 / 4} 
      alignItems={'flex-start'}
    >

          <h1>{t('analyzeYourDocuments')}</h1>
          <p>{t('documents.supportedDocuments')}</p>

          {currentConversationIsLoading && 
              <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor="#000" 
            />
          }

          <Flex 
            flexDirection={isMobile ? 'column' : 'row'} 
            justifyContent='space-between' 
            width='100%'
          >
              
              {documentsForDropdown &&
              <>
                  <DropdownCustom 
                    title={t('general.documents')}
                    currentOption={currentConversationId || ''}
                    options={documentsForDropdown}
                    handleOptionChange={(newConversationId: string) => {
                      setConversationId(newConversationId);
                      setDocumentMode && setDocumentMode('showDocument');
                      refetchCurrentConversation();
                    }}
                    isMobile
                  />

                  <MainButton 
                    key='analyzeYourDocuments'
                    title={t('general.addNewDocument')}
                    backgroundColor={colors.dark}
                    faSymbol={faUpload}
                    onClick={() => {
                      setCurrentFilename(null);
                      setIsFileUploaded(false);
                      setDocumentMode && setDocumentMode('addDocument');
                    }}
                  />
              </>                              
              }


              
                
          </Flex> 

        {(!isEmpty(documentConversations) && !isEmpty(currentFilename)) && 
          <>             
            <br />      
            <Conversation 
              userId={userId} 
              conversationId={currentConversationId || ''}
              isMobile={isMobile}
              isLoadingMutation={isLoadingMutation}
              currentConversation={currentConversation}
              errorMessage={errorMessage}
              textAreaValue={textAreaValue}
              captchaToken={captchaToken}
              handleChange={handleChange}
              handleSubmit={handleSubmit}              
            />            
          </>
          }

      {(isEmpty(documentConversations) || (documentMode === 'addDocument' && !isFileUploaded) ) &&
          <Flex flexDirection='column' sx={{ marginTop: '40px'}}>    
            
            <h1>{t('general.addNewDocument')}</h1>
            <UploadFile 
              userId={userId || ''} 
              isFileUploaded={isFileUploaded}
              setIsFileUploaded={setIsFileUploaded}
              setCurrentConversationId={setConversationId}
              refetchDocumentConversations={refetchDocumentConversations}
              refetchCurrentConversation={refetchCurrentConversation}
            />

        </Flex>        
      }    
    </Flex> 
  );
}