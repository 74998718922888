import React from "react";
import { useMutation } from "react-query";
import { StyledTextarea } from "./styled/StyledTextArea";
import api from "../common/api";
import { 
  SubmitButton,
} from "./styled/various";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

const fetchOutliers= async () => {
  const { data } = await api.get('http://localhost:5055/ipko_chatbot/x_UIIk23j932/get-outliers');
  return data;
};

export const IpkoOutliers: React.FC = () => {
  const { t } = useTranslation();
  const [text, setText] = React.useState('');

  // GET endpoint for ipko outliers
  const { error: outliersGetDataError } = useQuery<any, unknown>('myData', fetchOutliers, {
    onSuccess: (data) => {
      setText(data.text);
    }
  });

  // POST endpoint for ipko outliers
  const { mutate, isLoading } = useMutation((text: string) => {
    return api.post('http://localhost:5055/ipko_chatbot/x_UIIk23j932/save-outliers', { text });
  }, {
    onError: () => {
      toast.error('Error updating record');
    },
    onSuccess: () => {
      toast.info('Record updated successfully');
    }
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    mutate(text);
  };


    return (
      <div>
        <>
          <h1>Ipko AI agjenti: Pyetjet e veçanta</h1>
          {outliersGetDataError &&           
              <p style={{ color: 'red', fontWeight: 'bold' }}>{(outliersGetDataError as any).message}</p>            
          }

          <StyledTextarea
            disabled={isLoading}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your text here"
          />
          <SubmitButton
              disabled={text.length < 10 || isLoading}
              onClick={(e) => handleSubmit(e)}
          >
              {t('admin.modifyOutliers')}
          </SubmitButton>   
        </>   
      </div>
    );
};  

