import React from 'react';
import {
  useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Flex,
} from 'rebass';
import { 
  BackButton,
  TextInput,
  TextArea,
  SubmitButton,
  Label 
} from "./styled/various";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {  } from './styled/various';
import { useMutation } from 'react-query';
import { GetVectorByIdType } from '../hooks/admin/useGetVectorById';
import { backendUrl, projectName } from '../i18n';
import api from '../common/api';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

type UpdateVectorByIdType = {
  id: string,
  text: string,
  metadata: GetVectorByIdType | {}
}  

export const AdminVectorAddRecord = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const vectorId = uuidv4();

  const [textAreaValue, setTextAreaValue] = React.useState<string>('');
  const [urlValueForNewRecord, setUrlValueForNewRecord] = React.useState<string>('');


  
  const updateMutation = useMutation<AxiosResponse<any, any>, Error, UpdateVectorByIdType>(async () => {
    const response = await api.post(`${backendUrl}/${projectName}/vectors/upsertOne`, {
      id: vectorId || '',
      text: textAreaValue,
      metadata: { url: urlValueForNewRecord } || {}
    });
    return response;
  }, {
      onSuccess: () => {
        toast.info('Record added successfully');
        navigate(`/admin/vector/modify/${vectorId}`); 
      }
  });

  const { mutate: mutationUpdate, isLoading: isLoadingUpdate } = updateMutation; 



  const handleChange = (event: any) => {
    setTextAreaValue(event.target.value);
  };

  const handleURLChange = (event: any) => {
    setUrlValueForNewRecord(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const objectToMutate = {
        id: uuidv4(), 
        text: textAreaValue, 
        metadata: { url: urlValueForNewRecord } || { url: '' }
    }  
    
    mutationUpdate(objectToMutate);     

  };

  const goBack = () => {
		navigate(-1);
	}

  return (
    <Flex flexDirection={'column'}>
        <BackButton onClick={goBack} disabled={isLoadingUpdate} style={{ marginBottom: '50px'}}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px'}} />
          Kthehu mbrapa
        </BackButton>  

        <Flex flexDirection={'column'}>
          <Label>URL or unique identifier ID: <small>(*required)</small></Label>
          <TextInput 
            placeholder="Enter URL/ID" 
            onChange={handleURLChange} 
            required 
          /> 
        </Flex>



      <Label>Text to be stored in the database: <small>(*required)</small></Label>         
      <TextArea
        style={{
          marginTop: '10px'
        }}
        height="400px"
        placeholder={''}
        value={textAreaValue}
        onChange={handleChange} 
      />

      <br />
      <Flex 
        width={'70%'}
      >
        <SubmitButton
            disabled={isLoadingUpdate || textAreaValue.length < 10}
            onClick={(e) => handleSubmit(e)}
        >
            {t('admin.addRecord')}
        </SubmitButton>
      </Flex>  

    </Flex>
  );
}; 