import { useQuery } from 'react-query';
import api from '../../common/api';

type ProjectConversationType = {
  title: string;
  conversationId: string;
}

type ConversationsByProjectListType = {
  conversations: ProjectConversationType[],
  totalPages: number
}

export type ProjectNameType = 'ipkoChatbot' | 'prismaJs' | 'pragueArchitectureDepartment' | null;


type ProjectConversationsPostDataType = {
  projectName: ProjectNameType,
  pageNumber: number | undefined
}  

const fetchProjectConversations = async (
  { projectName, pageNumber }: ProjectConversationsPostDataType) : Promise<ConversationsByProjectListType> => {
  
    const response: { data: ConversationsByProjectListType } = await api.post(`/conversations/list`, { 
    projectName,
    pageNumber 
    });

  return response.data;
}

const useGetProjectConversations = (projectName: ProjectNameType, pageNumber: number | undefined) => {
  return useQuery(['ProjectConversations', projectName, pageNumber], () => fetchProjectConversations({ projectName, pageNumber}));
}

export default useGetProjectConversations;